import React, { Component } from "react";
import RemoteTable from "../components/RemoteTable";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import { readVendor } from "../modules/VendorModule";
import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import {
  addTollOutVendorPrice,
  editTollOutVendorPrice,
  readTollOutVendorPrice,
  readByMonthTollOutVendorPrice,
  overwriteTollOutVendorPrice,
  deleteTollOutVendorPrice,
} from "../modules/TollOutVendorPriceModule";
import RemoteAll from "../components/RemoteAll";

import { sendNotification } from "../modules/NotificationModule";

import DynamicTable from "../components/DynamicTableTollOutVendorPrice";

class TollOutVendorPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      submitted_create: false,
      showModal: false,
      showModalDailySpotPrice: false,
      loading: false,
      disableButtonNotification: false,
      seconds: 60,
      readOnly: false,
      isValidMaterial: true,
      isValidCustomer: true,
      isValidVendorCreate: true,
      currencyreadOnly: false,
      unitreadOnly: false,
      isClearable: true,
      collection: {},
      select_items: [],
      select_items_vendor: [],
      select_vendor_length: null,
      select_vendor_first_code: null,
      select_vendor_first_name: null,
      list_datatable: [],
      errors: {},
      errors_create: {},
      plan_date: null,
      month: null,
      id: null,
      requester: null,
      requester_name: null,
      material: null,
      material_code: null,
      segment: null,
      customer: null,
      customer_code: null,
      qty: null,
      unit: null,
      date: null,
      year: null,
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      search: "",
      material_create_code: null,
      vendor_create_code: null,
      data_toll_out: [
        {
          name: "",
          kg: "",
          price: "",
          uom: "",
        },
      ],
      data_toll_out_update: [
        {
          name: "",
          kg: "",
          price: "",
          uom: "",
        },
      ],
      columns_toll_out: [
        {
          text: "Name",
          type: "text",
        },
        {
          text: "Kg/UoM",
          type: "numeric",
        },
        {
          text: "Price (USD)",
          type: "numeric2",
        },
        {
          text: "UOM",
          type: "text2",
        },
      ],
      select_items_glaccount: [],
      select_items_uom: [],
      columns: [
        {
          dataField: "vendor_name",
          text: "Vendor",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "month",
          text: "Month",
          classes: "text-center",
          formatter: this.GetDateFormat,
          sort: true,
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
        },
      ],
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "created_at",
      sortOrder: "desc",
      defaultSorted: [
        {
          dataField: "month",
          order: "desc",
        },
      ],
      cellEditProps: {},
      filter_by: null,
      errors_search: {},
      isSearch: false,
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    readVendor().then((response) => {
      this.setState({
        select_items_vendor: response,
        select_vendor_length: response.length,
        select_vendor_first_code: response[0].value,
        select_vendor_first_name: response[0].label,
      });
    });

    const { sortBy, sortOrder, page, sizePerPage } = this.state;
    const query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;

    //read data daily spot on backend
    readTollOutVendorPrice(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });
    this.setState({
      date_create: new Date(),
      date_first: new Date(),
      date_second: subDays(new Date(), 7),
      requester: user.username,
      requester_name: user.details.hris_org_tree.current_person.person_name,
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }
  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  //change state date
  handleDate = (name, date) => {
    this.setState({
      [name]: date,
    });
  };
  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]: newValue.label,
        [name + "_code"]: newValue.value,
      });
      if (name == "material") {
        this.setState({
          isValidMaterial: true,
        });
      } else if (name == "customer") {
        this.setState({
          isValidCustomer: true,
        });
      } else if (name == "vendor_create") {
        this.setState({
          isValidVendorCreate: true,
        });
      }
    } else {
      this.setState({
        [name]: null,
        [name + "_code"]: null,
      });
      if (name == "material") {
        this.setState({
          isValidMaterial: false,
        });
      } else if (name == "customer") {
        this.setState({
          isValidCustomer: false,
        });
      } else if (name == "material_create") {
        this.setState({
          isValidMaterialCreate: false,
        });
      } else if (name == "vendor_create") {
        this.setState({
          isValidVendorCreate: false,
        });
      }
    }
  };

  //check diff date on day
  diff_date_on_day = (date1, date2) => {
    let diff_on_second = new Date(date2).getTime() - new Date(date1).getTime();
    return diff_on_second / (1000 * 60 * 60 * 24);
  };
  //date format on datatable
  GetDateFormat = (cell, row) => {
    const date_formatted = dateFormat(new Date(row.month), "yyyy-mm");
    return <div>{date_formatted}</div>;
  };
  //action button
  GetActionFormat = (cell, row) => {
    const { isEdit, isDelete } = this.state;
    return (
      <div>
        {isEdit && (
          <button
            key={row._id}
            type="button"
            className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            onClick={() =>
              this.toDetail(
                row._id,
                row.vendor_name,
                row.vendor_code,
                row.month,
                row.month_date,
                row.details
              )
            }
          >
            <i className="fas fa-edit" key={row._id}></i>
            &nbsp;Edit
          </button>
        )}

        {isDelete && (
          <button
            type="button"
            className="btn btn-primary btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            data-id={row._id}
            onClick={() =>
              this.toDetailView(
                row.vendor_name,
                row.vendor_code,
                row.month,
                row.month_date,
                row.details
              )
            }
          >
            <i class="fas fa-info-circle"></i>
            &nbsp;View
          </button>
        )}
      </div>
    );
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell, row) => {
    return <div>{thousandformat(cell, ".", ".", ",")}</div>;
  };

  deleteData = (id) => {
    const { history } = this.props;
    const { requester } = this.state;
    let collection = { _id: id, requester: requester };
    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, cancel entries",
      }).then((result) => {
        if (result.value) {
          deleteTollOutVendorPrice(collection)
            .then((response) => {
              toast.success("Data has been cancelled successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-salesforecast-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been cancelled successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-salesforecast-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Information", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  toDetail = (id, vendor_name, vendor_code, month, month_date, details) => {
    this.setState(
      {
        id: id,
        vendor_name: vendor_name,
        vendor_code: vendor_code,
        month: dateFormat(month, "mmmm yyyy"),
        month_date: month_date,
        read_type: "",
        data_toll_out_update: details,
      },
      () => {
        this.toggle();
      }
    );
  };

  toDetailView = (vendor_name, vendor_code, month, month_date, details) => {
    this.setState(
      {
        vendor_name: vendor_name,
        vendor_code: vendor_code,
        month: dateFormat(month, "mmmm yyyy"),
        month_date: month_date,
        read_type: "readonly",
        data_toll_out_update: details,
      },
      () => {
        this.toggle();
      }
    );
  };
  //toggle modal

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  //on modal hide

  onModalHide = () => {
    this.setState({
      id: null,
      vendor_name: null,
      vendor_code: null,
      month: null,
      month_date: null,
      read_type: null,
      data_toll_out_update: [
        {
          name: "",
          kg: "",
          price: "",
          uom: "",
        },
      ],
    });
    this.toggle();
  };
  //validate input
  validateForm = (currency, material, vendor, unit, date, category) => {
    let errors = this.state.errors;
    if (!currency || currency == null) {
      errors.currency = "This is field required";
    }
    if (!material) {
      errors.material = "This is field required";
      this.setState({
        isValidMaterial: false,
      });
    }
    if (!vendor) {
      errors.vendor = "This is field required";
      this.setState({
        isValidCustomer: false,
      });
    }
    if (!unit) {
      errors.unit = "This is field required";
    }
    if (!category) {
      errors.category = "This is field required";
    }
    if (!date) {
      errors.date = "This is field required";
    }

    return errors;
  };
  validateFormCreate = (vendor, date) => {
    let errors = this.state.errors_create;
    if (!vendor) {
      errors.vendor = "This is field required";
      this.setState({
        isValidVendorCreate: false,
      });
    }
    if (!date) {
      errors.date = "This is field required";
    }
    return errors;
  };

  validateSearch = (filter_by, search) => {
    let errors = this.state.errors_search;
    if (!filter_by) {
      errors.filter_by = "This is field required";
    }
    if (!search) {
      errors.search = "This is field required";
    }

    return errors;
  };
  //get data from backend
  reloadDataBackend = () => {
    const { sortBy, sortOrder, page, sizePerPage } = this.state;
    const query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    this.form.reset();
    this.refVendor.state.value.value = "";
    this.refVendor.state.value.label = "";
    readTollOutVendorPrice(query_string).then((response) => {
      this.setState({
        submitted_create: false,
        list_datatable: response ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
        plan_date: null,
        vendor_create_code: null,
        vendor_create: null,
        data_toll_out: [
          {
            name: "",
            kg: "",
            price: "",
            uom: "",
          },
        ],
      });
    });

    readVendor().then((response) => {
      this.setState({
        select_items_vendor: response,
        select_vendor_first_code: response[0].value,
        select_vendor_first_name: response[0].label,
      });
    });
  };
  handleTableChange = (type, { sortField, sortOrder, page, sizePerPage }) => {
    if (page == 0) {
      page = 1;
    }
    this.setState({
      sizePerPage: sizePerPage,
      page: page,
      sortBy: sortField,
      sortOrder: sortOrder,
    });

    const query_string = `sortBy=${sortField}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;

    readTollOutVendorPrice(query_string).then((response) => {
      this.setState({
        list_datatable: response ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });
  };

  handleNotification = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Please check your entries !",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        let payload = {
          requester: this.state.requester,
          requester_name: this.state.requester_name,
          page: "sales_forecast",
        };
        sendNotification(payload)
          .then((response) => {
            toast.success("Notification has been sent!", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-update-daily-spot-price-success",
            });
            Swal.fire({
              title: "Information",
              icon: "success",
              text: "Notification has been sent",
              showConfirmButton: false,
              timer: 2000,
            });
            this.setState({
              disableButtonNotification: true,
            });
            this.myInterval = setInterval(() => {
              const { seconds } = this.state;
              if (seconds > 1) {
                this.setState(({ seconds }) => ({
                  seconds: seconds - 1,
                }));
              } else {
                this.setState({
                  disableButtonNotification: false,
                  seconds: 60,
                });
                clearInterval(this.myInterval);
              }
            }, 1000);
          })
          .catch((err) => {
            toast.error(err, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-update-daily-spot-price-failed",
            });
            this.setState({
              loading: false,
            });
          });
      }
    });
  };
  handleSubmit = (e) => {
    this.setState({ submitted_create: true });
    const { history } = this.props;
    const {
      requester,
      vendor_create,
      vendor_create_code,
      plan_date,
      data_toll_out,
    } = this.state;

    if (!this.validateFormCreate(vendor_create, plan_date)) {
      return;
    }

    for (let obj = 0; obj < data_toll_out.length; obj++) {
      let pesan = "";
      if (data_toll_out[obj].name == "") {
        pesan += "Name can't empty <br>";
      }
      if (data_toll_out[obj].kg == "") {
        pesan += "Kg can't empty <br>";
      }
      if (data_toll_out[obj].price == "") {
        pesan += "Price can't empty <br>";
      }
      if (data_toll_out[obj].uom == "") {
        pesan += "UoM can't empty <br>";
      }
      if (pesan != "") {
        Swal.fire({
          title: "Warning",
          html: pesan,
          icon: "warning",
          confirmButtonText: "OK",
        });
        return;
      }
    }

    let date_formatted = dateFormat(plan_date, "yyyy-mm-01");
    let collection = {
      vendor_name: vendor_create,
      vendor_code: vendor_create_code,
      month: date_formatted,
      created_by: requester,
      details: data_toll_out,
    };
    if (requester && vendor_create && plan_date) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          addTollOutVendorPrice(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                //read data exchange rate on backend
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-failed",
              });
              if (
                err ===
                "data is already exist, Are you sure to overwrite data ?"
              ) {
                Swal.fire({
                  title: "Data is already exist",
                  text: `Are you sure to overwrite data ?`,
                  icon: "info",
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                }).then((result) => {
                  if (result.value) {
                    overwriteTollOutVendorPrice(collection)
                      .then((response) => {
                        toast.success("Data has been updated successfully", {
                          position: "top-right",
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          toastId: "customId-update-daily-spot-price-success",
                        });
                        Swal.fire({
                          title: "Information",
                          icon: "success",
                          text: "Data has been updated successfully",
                          showConfirmButton: false,
                          timer: 2000,
                        }).then((result) => {
                          //read data exchange rate on backend
                          this.reloadDataBackend();
                        });
                      })
                      .catch((err) => {
                        toast.error(err, {
                          position: "top-right",
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          toastId: "customId-update-daily-spot-price-failed",
                        });
                        this.setState({
                          loading: false,
                        });
                      });
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire("Cancelled", "Your data is safe :)", "error");
                  }
                });
              }
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  //handle submit edit
  handleSubmitEdit = () => {
    this.setState({ submitted: true });

    //get department
    const user = JSON.parse(localStorage.getItem("user"));
    let department = user.details.hris_org_tree.current_person.nama_department;

    const { id, requester, data_toll_out_update } = this.state;

    let collection = {
      _id: id,
      created_by: requester,
      details: data_toll_out_update,
    };
    for (let obj = 0; obj < data_toll_out_update.length; obj++) {
      let pesan = "";
      if (data_toll_out_update[obj].name == "") {
        pesan += "Name can't empty <br>";
      }
      if (data_toll_out_update[obj].kg == "") {
        pesan += "Kg can't empty <br>";
      }
      if (data_toll_out_update[obj].price == "") {
        pesan += "Price can't empty <br>";
      }
      if (data_toll_out_update[obj].uom == "") {
        pesan += "UoM can't empty <br>";
      }
      if (pesan != "") {
        Swal.fire({
          title: "Warning",
          html: pesan,
          icon: "warning",
          confirmButtonText: "OK",
        });
        return;
      }
    }

    if (id && requester && data_toll_out_update) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, update entries",
      }).then((result) => {
        if (result.value) {
          editTollOutVendorPrice(collection)
            .then((response) => {
              this.toggle();
              this.setState({
                submitted: false,
              });
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-update-daily-spot-price-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-update-daily-spot-price-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  //handle search
  handleSearch = () => {
    const { search, page, sizePerPage, filter_by, sortBy, sortOrder } =
      this.state;
    const query_string = `page=${page}&sizePerPage=${sizePerPage}&search=${search}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

    readTollOutVendorPrice(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.foundData ? response.countData : 0,
        page: response.foundData ? response.currentPage : 1,
      });
    });
  };

  //handle copy by month
  handleCopy = () => {
    const { vendor_create_code, plan_date } = this.state;
    let date_formatted = dateFormat(plan_date, "yyyy-mm-01");
    const query_string = `month=${date_formatted}&vendor_code=${vendor_create_code}`;

    if (vendor_create_code && plan_date) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries vendor and month !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          readByMonthTollOutVendorPrice(query_string)
            .then((response) => {
              toast.success("Success get data", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-success",
              });
              this.setState({
                data_toll_out: response?.foundData[0].details ?? [],
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-failed",
              });
              if (err === "Data not found") {
                Swal.fire({
                  title: "Not Found",
                  text: `Data not found`,
                  icon: "info",
                  confirmButtonText: "OK",
                });
              }
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
    } else {
      Swal.fire("Error", "Vendor and Month can't be empty", "error");
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    let {
      columns,
      loading,
      filter_by,
      defaultSorted,
      showModal,
      cellEditProps,
      select_items_vendor,
      select_vendor_length,
      select_vendor_first_code,
      list_datatable,
      id,
      vendor_create,
      vendor_create_code,
      vendor_name,
      month,
      read_type,
      errors,
      errors_create,
      submitted_create,
      submitted,
      isValidMaterial,
      isValidMaterialCreate,
      isValidCustomer,
      isValidVendorCreate,
      isClearable,
      isCreate,
      isRead,
      search,
      page,
      sizePerPage,
      totalSize,
      data_toll_out,
      data_toll_out_update,
      columns_toll_out,
      plan_date,
    } = this.state;
    const customStylesMaterial = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidMaterial
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidMaterial
            ? "#ddd"
            : "green",
        },
      }),
    };
    const customStylesCustomer = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidCustomer
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidCustomer
            ? "#ddd"
            : "green",
        },
      }),
    };
    const customStylesMaterialCreate = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidMaterialCreate
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidMaterialCreate
            ? "#ddd"
            : "green",
        },
      }),
    };
    const customStylesCustomerCreate = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidVendorCreate
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidVendorCreate
            ? "#ddd"
            : "green",
        },
      }),
    };
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              noValidate
            >
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>
                  {id ? "Update" : ""} Toll Out Vendor Price
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={1}>
                      <div className="form-group">
                        <label htmlFor="currency">Vendor</label>
                      </div>
                    </Col>

                    <Col xs={12} md={3}>
                      <div
                        className={
                          errors_create.vendor != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <input
                          className="form-control form-control-sm"
                          name="vendor"
                          defaultValue={vendor_name}
                          onChange={this.onChangeValue}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={1}>
                      <div className="form-group">
                        <label htmlFor="currency">Month</label>
                      </div>
                    </Col>

                    <Col xs={12} md={3}>
                      <div
                        className={
                          errors_create.date != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <input
                          className="form-control form-control-sm"
                          name="month_date"
                          value={month || ""}
                          onChange={this.onChangeValue}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={12}>
                      <DynamicTable
                        data={data_toll_out_update}
                        columns={columns_toll_out}
                        read_type={read_type}
                        onChange={(data) => {
                          this.setState({
                            data_toll_out_update: [...data],
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                {id && (
                  <Button
                    variant="success"
                    onClick={this.handleSubmitEdit}
                    className="float-right"
                  >
                    <i className="fas fa-save" /> Update
                  </Button>
                )}
              </ModalFooter>
            </form>
          </Modal>
          <Header />

          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">
                            Toll Out Vendor Price List
                          </h3>
                        </div>
                        <div className="card-body">
                          <form
                            autoComplete="off"
                            className={
                              submitted_create
                                ? "needs-validation was-validated"
                                : "needs-validation"
                            }
                            noValidate
                            ref={(form) => (this.form = form)}
                          >
                            {/* input vendor */}
                            <Row>
                              <Col xs={12} md={1}>
                                <div className="form-group">
                                  <label htmlFor="currency">Vendor *</label>
                                </div>
                              </Col>

                              <Col xs={12} md={3}>
                                <div
                                  className={
                                    errors_create.vendor !=
                                    "This field is required"
                                      ? "form-group"
                                      : "form-group has-error has-feedback"
                                  }
                                >
                                  <Select
                                    onChange={this.handleChange.bind(
                                      this,
                                      "vendor_create"
                                    )}
                                    options={select_items_vendor}
                                    value={
                                      select_items_vendor.filter(
                                        (e) => e.value === vendor_create_code
                                      ) || ""
                                    }
                                    isClearable={isClearable}
                                    ref={(ref) => (this.refVendor = ref)}
                                  />

                                  {!isValidVendorCreate && (
                                    <div
                                      style={{
                                        width: "100%",
                                        marginTop: ".25rem",
                                        fontSize: "80%",
                                        color: " #dc3545",
                                      }}
                                    >
                                      {errors_create.vendor}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>

                            {/* input month */}
                            <Row>
                              <Col xs={12} md={1}>
                                <div className="form-group">
                                  <label htmlFor="currency">Month *</label>
                                </div>
                              </Col>

                              <Col xs={12} md={3}>
                                <div
                                  className={
                                    errors_create.plan_date !=
                                    "This field is required"
                                      ? "form-group"
                                      : "form-group has-error has-feedback"
                                  }
                                >
                                  <DatePicker
                                    selected={plan_date}
                                    disabledKeyboardNavigation
                                    onChange={this.handleDate.bind(
                                      this,
                                      "plan_date"
                                    )}
                                    name="plan_date"
                                    wrapperClassName="datePickerCustom"
                                    dateFormat="MMMM yyyy"
                                    className="form-control form-control-sm"
                                    required
                                    showMonthYearPicker
                                  />
                                  {errors.plan_date && (
                                    <div className="invalid-feedback">
                                      {errors.date}
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col xs={12} md={3}>
                                <Button
                                  variant="info"
                                  onClick={this.handleCopy}
                                >
                                  Copy from Previous Month
                                </Button>
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={12} md={8}>
                                <DynamicTable
                                  data={data_toll_out}
                                  read_type={read_type}
                                  columns={columns_toll_out}
                                  onChange={(data) => {
                                    this.setState({
                                      data_toll_out: [...data],
                                    });
                                  }}
                                />
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={12} md={12}>
                                <Button
                                  variant="success"
                                  onClick={this.handleSubmit}
                                  className="float-right"
                                >
                                  <i className="fas fa-save" /> Submit
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isRead && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Historical Data</h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <form autoComplete="off">
                            <Row>
                              <Col xs={12} md={2}>
                                <div className="form-group">
                                  <input
                                    className="form-control form-control-sm"
                                    name="search"
                                    value={search || ""}
                                    placeholder="Search ..."
                                    onKeyPress={(e) => {
                                      e.key === "Enter" && e.preventDefault();
                                    }}
                                    onChange={this.onChangeValue}
                                  />
                                </div>
                              </Col>
                              <Col>
                                <Button
                                  variant="info"
                                  onClick={this.handleSearch}
                                  className="float-left"
                                >
                                  <i className="fas fa-search" /> Search
                                </Button>
                              </Col>
                            </Row>
                          </form>
                          <br />
                          {/*TODO still dummy data */}
                          <RemoteAll
                            data={list_datatable}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            cellEditProps={cellEditProps}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            onTableChange={this.handleTableChange.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(TollOutVendorPrice);
